<template>
  <div class="main">
    <!-- <div class="spinner" :class="[isOut ? 'out' : '']">
      <div class="inner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div> -->
    <div class="emp_h"></div>
    <div class="wrap">
      <div class="b-info">
        <div class="right" data-aos="fade-up">
          <div class="video-box" v-if="isvideo">
            <div
              class="img"
              v-if="index_setting"
              :style="'background-image: url(' + index_setting.logo + ');'"
            ></div>
          </div>
          <div class="video-box" v-else>
            <video
              v-if="index_setting"
              class="video"
              id="video"
              ref="video"
              autoplay
              loop
              muted
              playsinline
              x-webkit-airplay="true"
              x5-video-player-type="h5"
              x5-video-orientation="portraint"
              x5-playsinline="true"
              webkit-playsinline="true"
            >
              <source :src="index_setting.logo" />
            </video>
            <div
              v-if="btnhide"
              class="play"
              id="playbtn"
              @click="playVideo"
            ></div>
          </div>
        </div>
        <div class="left" data-aos="fade-up">
          <div class="txt">
            <span>Honggang You</span> is a <br />UIUX & Brand Designer
          </div>
        </div>
      </div>
      <div class="work_con" id="page2">
        <div class="g-fixed" id="fixed_el" :class="{ fixed: isFixed }">
          <div class="m-titb1">
            <div class="r">
              <div class="links">
                <span
                  class="s"
                  :class="[cid == item.id ? 'on' : '']"
                  v-for="(item, index) of cate"
                  :key="item.id"
                  @click="switchCate(item.id, index)"
                  >{{ item.title }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="pro-line"></div>
        <!-- 列表 -->
        <ul class="ul-pros" v-if="list.length">
          <li v-for="(item, index) in list" :key="index">
            <div
              class="con"
              data-aos="fade-up"
              :data-aos-delay="index % 2 == 0 ? '0' : '300'"
              @click.stop="showDetail(item.id)"
              :data-id="item.id"
            >
              <div class="pic">
                <img class="img" :src="item.image" alt="load error" />
              </div>
              <div class="name">
                {{ item.title ? item.title : "暂无标题" }}
              </div>
            </div>
          </li>
        </ul>

        <!-- <div class="loadBtnbox" v-if="isLoadStatus">
          <el-button class="loadBtn" type="primary" :loading="true"></el-button>
        </div> -->
        <div
          class="g-loadmore"
          v-if="!isLoad && nums > list.length"
          @click="loadMore"
        >
          <span class="tit">{{ loadTxt }}</span>
        </div>
        <!-- <div class="loadBtnbox" v-if="isLoad && isLoadStatus"> -->
        <!-- <div class="loadBtnbox" v-if="isLoad && list.length != 0">
          <el-button
            class="loadBtn"
            type="primary"
            :loading="isLoad"
          ></el-button>
        </div> -->
        <!-- <el-empty v-if="list.length == 0" description="暂无数据"></el-empty> -->
      </div>
    </div>
    <!-- 密码弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      custom-class="n-pop"
      :close-on-click-modal="false"
    >
      <div class="n-form">
        <div class="tit">输入密码查看</div>
        <img src="@/assets/images/new/n-p1.png" alt="" class="bg" />
        <div class="gp">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="0px"
            class="demo-ruleForm"
          >
            <el-form-item label="" prop="password" ref="pwdinp">
              <el-input
                type="password"
                v-model="ruleForm.password"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="bot">
          <div class="btn" @click="dialogVisible = false">取消</div>
          <div class="btn btn1" @click="checkPwd">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AOS from "aos";
import { Loading } from 'element-ui';
import $ from 'jquery'
import wx from 'jweixin-module'
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value !== this.alpwd) {
        callback(new Error('密码错误'));
      } else {
        callback();
      }
    }
    return {
      isFixed: false,


      isOut: false,
      swiper: null,
      banner: [],
      sj_banner: [],
      cid: '',
      cate: [],

      url: '',
      isCopy: false,
      loading: true,
      isLoad: true,
      loadTxt: 'See more',
      isFirst: true,
      loadEnd: false,

      isLoadStatus: false, //列表加载中
      btnhide: true, //头像视频播放按钮
      isvideo: false,


      nums: 0,
      page: 1,
      pageSize: 6,
      list: [],
      dialogVisible: false,
      col: 2,

      index_setting: null,



      showTel: false,
      hideTime: 0,

      headH: 0,
      screenWidth: document.documentElement.clientWidth,//屏幕宽度

      detailsData: {},
      clickid: '',
      ruleForm: {
        password: null,
      },
      rules: {
        password: [
          // { validator: validatePass, trigger: 'blur' },
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      },

    }
  },

  mounted () {
    let that = this;




    window.addEventListener('scroll', this.handleScroll)
    /* new WOW({
      live: true
    }).init() */
    AOS.init({
      offset: 0,
      duration: 1000,
    })

    this.getIndex();
  },


  methods: {

    handleScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      let offsetTop;
      if ($('#fixed_el').length) {
        offsetTop = $('#fixed_el').offset().top
      }

      if (scrollTop > offsetTop) {
        this.isFixed = true
      } else {
        this.isFixed = false
      }
    },





    // 判断是否为视频
    matchFileSuffixType (fileName) {
      // 后缀获取
      var suffix = ''
      // 获取类型结果
      var result = ''
      try {
        var flieArr = fileName.split('.')
        suffix = flieArr[flieArr.length - 1]
      } catch (err) {
        suffix = ''
      }
      /*  // fileName无后缀返回 false
       if (!suffix) {
         result = false
         return result
       } */
      // 图片格式
      var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item == suffix
      })
      if (result) {
        result = 'image'
        return true
      } else {
        return false
      }


    },
    // axio
    getIndex () {
      let that = this;
      let data = {

      };
      that.$http
        .getIndex(data)
        .then((res) => {
          if (res.ret == 0) {
            let result = res.data
            that.index_setting = result.index_setting //头部设置
            that.cate = result.cate  //分类
            that.cid = result.cid  //默认分类id
            that.isvideo = that.matchFileSuffixType(result.index_setting.logo)


            /* setTimeout(() => {
              console.log(myVideo,that.$refs.video);
              
            }, 200); */
            that.$nextTick(function () {
              let myVideo = that.$refs.video
              if (myVideo) {
                setTimeout(() => {
                  myVideo.play();
                  // that.btnhide = false;
                }, 200);
                document.addEventListener('click', function () {
                  myVideo.play();
                  that.btnhide = false;
                })
                myVideo.addEventListener('play', function () {
                  // alert('play')
                  that.btnhide = false;
                })
              }

              wx.config({})
              wx.ready(function () {
                let myVideo = that.$refs.video
                // alert(1)
                if (myVideo) {
                  setTimeout(() => {
                    myVideo.play();
                    // that.btnhide = false;
                  }, 200);
                  document.addEventListener('click', function () {
                    myVideo.play();
                    that.btnhide = false;
                  })
                  myVideo.addEventListener('play', function () {
                    // alert('play')
                    that.btnhide = false;
                  })
                }
              })




            })



            /* setTimeout(function () {
              that.isOut = true;
            }, 1000); */

            that.getList(that.cid);
            /* that.$nextTick(function () {
                that.initSwiper();
            }) */
          }
        })
    },
    // 获取图片类别
    getList (cid, page) {
      let that = this;
      let data = {
        page: that.page,
        cid: cid
      };
      that.$http
        .getList(data)
        .then((res) => {
          if (res.ret == 0) {
            // console.log(res);
            let result = res.data


            if (result.list.length == 0) {
              that.list = []
              that.nums = result.nums
              that.loadEnd = true
              return false
            }
            that.isLoadStatus = false
            that.nums = result.nums
            that.loading = false
            that.loadEnd = true
            that.isLoad = false
            that.list = that.list.concat(result.list);


          } else {
            that.loading = false
          }
        }).catch((err) => {
          that.loading = false
        })
    },
    // 切换分类
    switchCate (cid, idx) {
      let that = this
      // if (that.cid != cid && that.loadEnd) {
      if (that.cid != cid) {

        /* if (that.screenWidth > 900) {
          $("body,html").animate({
            scrollTop: $(window).height() - that.headH
          }, 0)
        } */


        that.loading = true;
        that.isLoad = true;
        that.isFirst = false;
        that.isLoadStatus = true
        that.page = 1
        that.cid = cid
        that.loadEnd = false
        that.list = [];
        that.getList(cid);
        that.$nextTick(function () {
          var sleft = $('.m-titb1 .on').position().left, contenwidth = $('.m-titb1').width(), itemw = $('.m-titb1 .on').width();
          $('.m-titb1 .links').animate({
            scrollLeft: (sleft - (contenwidth - itemw) / 2) + 'px'
          });
        });
      } else {
        return false
      }
    },
    // 加载更多
    loadMore () {
      let that = this;
      // that.loadTxt = '加载中'
      that.isLoad = true;
      that.isLoadMore = true;
      if (that.nums > that.page * that.pageSize) {
        that.loadEnd = false
        that.page += 1
        that.getList(that.cid, that.page);
      } else {
        that.isLoadStatus = false
      }

    },




    // 显示详情弹窗
    showDetail (id) {
      let that = this

      localStorage.setItem('yhgDetailsId', id);
      that.clickid = id
      // 判断是否加密
      let data = {
        id: id
      };
      that.$http
        .getDetail(data)
        .then((res) => {
          if (res.ret == 0) {
            // console.log(res);
            let result = res.data
            // flag 1：未加密 2：加密
            if (result.flag == 1) {
              that.$router.push({
                path: '/details/'+that.clickid,
              })
            } else if (result.flag == 2) {
              that.alpwd = result.pwd
              that.ruleForm.password = ''
              that.dialogVisible = true
            }
          }
        });


    },
    checkPwd () {
      let that = this
      // console.log(that.alpwd, that.ruleForm.password);
      let data = {
        id: that.clickid,
        pwd: that.ruleForm.password
      };
      that.$http
        .checkPwd(data)
        .then((res) => {
          if (res.ret == 0) {

            that.$message({
              message: '验证成功！',
              type: 'success',
              duration: 1000
            });
            window.localStorage.setItem('yhgworklogin', 1)
            setTimeout(() => {
              that.dialogVisible = false
              that.$router.push({
                path: '/details/'+that.clickid,
               
              })
            }, 1200);

          } else {
            that.$message({
              message: res.msg,
              type: 'warning',
              duration: 1000
            });
          }
        })
      /* if (that.alpwd == null) {
        that.$refs.pwdinp.resetField();
        that.dialogVisible = false
        that.$router.push({
          name: 'Details',
          params: {
            // detailsData: that.detailsData
            id: that.clickid
          }
        })
        return;
      } */

      /* this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.dialogVisible = false
          that.$router.push({
            name: 'Details',
            params: {
              // detailsData: that.detailsData
              id: that.clickid
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      }); */


    },


    playVideo () {
      let that = this
      that.$refs.video.play();
      that.btnhide = false;
    },

  },

  beforeDestroy () {
    window.removeEventListener("scroll", this.handleScroll);
  },
  activated () {
    if (this.$refs.video) {
      this.$refs.video.play();
      this.btnhide = false;
    }

  },


}
</script>
<style>
.el-loading-mask {
  background-color: rgba(255, 255, 255, 0);
}
.el-loading-spinner {
  display: none;
}
</style>